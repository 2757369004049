import React, {useEffect, useRef, useState} from "react";
import {
    Table,
    Tooltip,
    Popconfirm,
    Modal, InputBox,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {fetchCustomer} from "./actions";
import {deleteCustomerUrl, getTransaction} from "./api";
import {useNavigate} from "react-router-dom";
import {getToken} from "../../request";
import {
    notification,
    DefaultTablePagination,
} from "../../components/Elements/appUtils";
import axios from "axios";
import _ from "lodash";
import TransactionComp from "../../components/transactionComp"
import SaleDetailModal from "../patients/components/saleDetailModal";
import moment from "moment";

const CustomerList = () => {
    const [customerData, setCustomerData] = useState([]);
    const [customerId, setCustomerId] = useState("");
    const [report, setReport] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [searchMobile, setSearchMobile] = useState("");
    const [searchGroup, setSearchGroup] = useState("");
    const [gstType, setGstType] = useState("");
    const [customerType, setCustomerType] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSaleModalOpen, setSaleIsModalOpen] = useState({
        visible: false,
        customerId: ''
    });

    let tableRef = useRef();
    const navigate = useNavigate();

    const [customerDetails, setCustomerDetails] = useState({
        customerName: "",
        customerState: "",
        customerInvoice: "",
        customerNumber: "",
        purchaseDate: "",
        balance: "",
    });

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.type = {$in: ["customer", "patient"]};
            if (searchName) {
                params.name = searchName
            }
            if (searchEmail) {
                params.email = searchEmail
            }
            if (searchMobile) {
                params.mobile = searchMobile
            }
            if (gstType) {
                params.gstType = gstType
            }
            if (customerType) {
                params.type = customerType
            }

            params.customerGroupName = {$nin: ['EXPENSES (INDIRECT)']};
            const data = await fetchCustomer({...params, regExFilters: ["name", "email", "mobile"],});
            setCustomerData(data.data);

            resolve(data);

        });
    };

    let loadColumn = () => {
        let columnList = [];
        _.each(columns, (item) => {
            if (!item.hidden) {
                columnList.push(item);
            }
        });
        return columnList;
    };

    const fetchcustomerdata = () => {
        const allrecordofcustomer = customerData.filter(
            (item) => item._id == customerId
        );

        allrecordofcustomer.map((i) => {
            setCustomerDetails({
                customerName: i.name,
                customerState: i.state,
                customerNumber: i.mobile,
                customerInvoice: i.invoiceNumber,
                purchaseDate: i.purchaseDate,

                balance: i.balance,
            });

            return;
        });
    };
    useEffect(() => {

        fetchcustomerdata();
    }, [customerId]);
    const deleteCustomer = async (id) => {
        try {
            let config = {
                ...(await getToken()),
            };
            const response = await axios.delete(deleteCustomerUrl(id), config);
            console.log(response, "response");
            if (response.data.error) {
                notification.error({
                    message: response.data.message || "Error",
                });
            } else {
                tableRef.current.reload();
                notification.success({
                    message: response.data.message,
                });
            }
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    const fetchTransaction = async (dispach) => {
        try {
            let config = {
                params: {customerId},
                ...(await getToken()),
            };

            const response = await axios.get(getTransaction(customerId), config);
            let filter = response.data.data.reverse()
            setReport(filter);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        if (customerId) {
            fetchTransaction();
        }
    }, [customerId]);

    useEffect(() => {
        if (isModalOpen) {
            fetchTransaction();
        }
    }, [isModalOpen]);

    console.log(report, "report");
    const columns = [
        {
            title: "#",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
            width: 50,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 130,
            render: (text, record) => (
                <>
                    {record.name && record.name}<br/>
                    <b>{record.uhid && `UHID - ${record.uhid} `}</b> <br/>
                    {record.mobile && (<b>📞 - {record.mobile}</b>)}
                </>
            )
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 100,
        },
        {
            title: "Source Medium / Sale Category",
            dataIndex: "sourceMedium",
            key: "sourceMedium",
            width: 100,
            render: (text, record) => (
                <>
                    {record.sourceMedium && record.sourceMedium}
                    {record.sourceMedium && record.saleCategory && record.saleCategory.name ? ' / ' : ''}<br/>
                    {record.saleCategory && record.saleCategory.name && record.saleCategory.name}
                </>
            ),
        },
        {
            title: "GST Type",
            dataIndex: "gstType",
            key: "gstType",
            width: 100,
            render: (text, record) => (
                <>
                    {record.gstType && record.gstType} <br/>
                    {record.gstType === 'Registered' && record.gstIn && (
                        <>
                            ({<b>{record.gstIn}</b>})
                        </>
                    )}
                </>
            ),
        },
        {
            title: "Group",
            dataIndex: "customerGroupName",
            key: "customerGroupName",
            width: 75,
        },
        {
            title: "Balance",
            dataIndex: "balance",
            key: "balance",
            width: 50,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            width: 70,
        },
        {
            title: "Added on",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 75,
            render: (text, record) => (
                <>
                    {
                        record.addedOn ? (
                                moment(record.addedOn).format("DD/MM/YYYY")
                            ) :
                            moment(record.createdAt).format("DD/MM/YYYY")
                    }
                </>
            ),
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            width: 100,
            render: (text, record) => (
                <>
                    {record.type === 'customer' ? (
                        <>
                            {record.shippingAddress && `${record.shippingAddress}, `}
                            {record.shippingCountry && `${record.shippingCountry}, `}
                            {record.shippingPincode && `${record.shippingPincode}`}
                        </>
                    ) : (
                        <>
                            {record.address && `${record.address}, `}
                            {record.city && `${record.city}, `}
                            {record.state && `${record.state}, `}
                            {record.country && `${record.country}`}
                        </>
                    )}
                </>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 190,
            render: (val, record) => {
                return (
                    <>
                        <a
                            className='empty_btn'
                            onClick={() => {
                                navigate(`/edit-customer?_id=${record._id}`);
                            }}
                        >
                            <img src={"./edit.svg"}/>
                        </a>
                        <Tooltip title={"Delete"}>
                            <Popconfirm
                                className="empty_btn"
                                title={"Are your sure, you want to delete Customer?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    deleteCustomer(record._id);
                                }}
                            >
                                <img src={"./close.svg"}/>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={"View Transactions"}>
                            <a
                                className='empty_btn'
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setCustomerId(record._id);
                                }}
                            >
                                <i className={"fa fa-eye far"}/>
                            </a>
                        </Tooltip>
                        <Tooltip title={"Previous record"}>
                            <a
                                className='empty_btn'
                                onClick={() => {
                                    setSaleIsModalOpen({visible: true, customerId: record._id});
                                }}
                            >
                                <i className={"fa fa-eye far"}/>
                            </a>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const filterCustomer = () => {
        // setShowFilter(true);
        // if (
        //   !searchName &&
        //   !searchEmail &&
        //   !searchMobile &&
        //   !searchGst &&
        //   !searchGroup &&
        //   !searchAddress
        // ) {
        //   // setCustomerData([])
        //   notification.error({ message: "please enter name" });
        //   setShowFilter(false);
        //   return;
        // }
        // const filteredCustomer = customerData.filter((customer) => {
        //   return (
        //     (customer.name?.toLowerCase().includes(searchName.toLowerCase()) ||
        //       searchName === "") &&
        //     (customer.email?.toLowerCase().includes(searchEmail.toLowerCase()) ||
        //       searchEmail === "") &&
        //     (customer.mobile?.toLowerCase().includes(searchMobile.toLowerCase()) ||
        //       searchMobile === "") &&
        //     (customer.gstType?.toLowerCase().includes(searchGst.toLowerCase()) ||
        //       searchGst === "") &&
        //     (customer.customerGroupName
        //       ?.toLowerCase()
        //       .includes(searchGroup.toLowerCase()) ||
        //       searchGroup === "") &&
        //     ((typeof customer.address === "string" &&
        //       customer.address.includes(searchAddress)) ||
        //       searchAddress === "")
        //   );
        // });
        //
        // setCustomerData(filteredCustomer);
        setShowFilter(!showFilter)
    };

    const clearCustomerFilters = () => {
        setSearchName("");
        setSearchEmail("");
        setSearchMobile("");
        setSearchGroup("");
        setGstType("");
        setCustomerType("");
        setShowFilter(!showFilter);


        //setCustomerData(filteredData);


    };

    return (
        <PageHeader
            title={"Customer/Patient"}
            extraLink={[
                {
                    name: "Add Customer",
                    link: "/add-customer",
                },
                {
                    name: "Add Patient",
                    link: "/add-patient",
                },
            ]}
        >
            <div className="card">
                <div className="card-body">
                    <div className="mb-3 row">
                        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft: "7px"}}>
                            <label htmlFor="searchName" className="form-label"/>

                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchName"
                                placeholder="Search by Name"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft: "7px"}}>
                            <label htmlFor="searchEmail" className="form-label"/>
                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchEmail"
                                placeholder="Search by email"
                                value={searchEmail}
                                onChange={(e) => setSearchEmail(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft: "7px"}}>
                            <label htmlFor="searchMobile" className="form-label"/>
                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchMobile"
                                placeholder="Search by Mobile"
                                value={searchMobile}
                                onChange={(e) => setSearchMobile(e.target.value)}
                            />
                        </div>
                        <select
                            className="form-control search-filters"
                            onChange={({target: {value}}) => {
                                setGstType(value);
                            }}
                            value={gstType}
                            required
                            style={{
                                height: "46px",
                                textAlign: "left",
                                paddingLeft: "21px",
                                marginTop: "20px",
                                marginLeft: "10px",
                                fontSize: '14px',
                                width: '200px'
                            }}
                        >
                            <option value={''} disabled={true}>
                                Select Gst Type
                            </option>
                            <option value={'Registered'}>
                                Registered
                            </option>
                            <option value={'Unregistered'}>
                                Unregistered
                            </option>
                        </select>
                        <select
                            className="form-control search-filters"
                            onChange={({target: {value}}) => {
                                setCustomerType(value);
                            }}
                            value={customerType}
                            required
                            style={{
                                height: "46px",
                                textAlign: "left",
                                paddingLeft: "21px",
                                marginTop: "20px",
                                marginLeft: "10px",
                                fontSize: '14px',
                                width: '230px'
                            }}
                        >
                            <option value={''} disabled={true}>
                                Select Type (Patient/Customer)
                            </option>
                            <option value={'customer'}>
                                Customer
                            </option>
                            <option value={'patient'}>
                                Patient
                            </option>

                        </select>

                        {/* <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchAddress" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchAddress"
                placeholder="Search by Address"
                value={searchAddress}
                onChange={(e) => setSearchAddress(e.target.value)}
              />
            </div>*/}
                    </div>

                    <div className="mb-3 search-buttons">
                        <div className="search-wrap pe-2">
                            <button className="btn btn-info" onClick={filterCustomer}>
                                Search
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button
                                className="btn btn-secondary"
                                onClick={clearCustomerFilters}
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    {showFilter ? <div><Table
                        apiRequest={apiRequest}
                        columns={loadColumn()}
                        ref={tableRef}
                        position={"top"}
                        pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                        extraProps={{scroll: {x: 100},}}
                    /></div> : <Table
                        apiRequest={apiRequest}
                        columns={loadColumn()}
                        ref={tableRef}
                        position={"top"}
                        pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                        extraProps={{scroll: {x: 100},}}
                    />}

                </div>
            </div>
            {isModalOpen ? (
                <TransactionComp
                    visible={isModalOpen}
                    Report={report}
                    customerDetails={customerDetails}
                    onClose={() => {
                        setIsModalOpen(false);
                        setReport([]);

                    }}
                />
            ) : null}
            {isSaleModalOpen.visible ? (
                <SaleDetailModal
                    className={"mt-4"}
                    visible={isSaleModalOpen.visible}
                    onClose={() => {
                        setSaleIsModalOpen({visible: false, customerId: ''});
                    }}
                    customerId={isSaleModalOpen.customerId}
                />
            ) : null}
        </PageHeader>
    );
};

export default CustomerList;
