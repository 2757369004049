import React, {useState, useEffect} from "react";
import {
    Form,
    notification,
    HyperLink,
    fixed2Digit,
    InputBox,
    Card,
    Modal,
    Button,
} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {
    States,
    PaymentTypes,
    IndianStates,
    shipthrough,
} from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import _, {invertBy} from "lodash";
import ItemListComponent from "./ItemListComponent";
import {addSaleFxn, fetchCustomer, fetchSingleUser} from "./actions";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import ChooseCustomer from "../customer/chooseCustomer";
import {useNavigate} from "react-router-dom";
import {fetchBank} from "../customer/actions";
import moment from "moment";
import {fetchSaleCategory, fetchVisitNumber} from "../category/actions";
import {fetchVisitAllDetailsFxn} from "../patients/components/action";
import {fetchCreditNoteByCustomerId} from "../creditNote/actions";
import SaleDetailModal from "../patients/components/saleDetailModal";

function Sales(props) {

    const initialState = [
        {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
    ]
    const [isSaleModalOpen, setSaleIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [showDeleteButtons, setShowDeleteButtons] = useState([false]);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [requestedMedModal, setRequestedMedModal] = useState(false);

    const handleInputChange = (index, field, value) => {
        const newData = [...formData];
        newData[index][field] = value;
        setFormData(newData);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.map((btn, idx) => (idx === index ? true : btn))
        );
    };

    const handleDelete = (index) => {
        const newPayments = [...formData];
        newPayments.splice(index, 1);
        setFormData(newPayments);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.filter((_, idx) => idx !== index)
        );
    };

    const handleAddField = () => {
        const amountsArray = formData.map((data) => data.amount);
        const totalAmount = amountsArray.reduce(
            (accumulator, currentValue) => accumulator + parseFloat(currentValue),
            0
        );

        if (totalAmount > getFieldValue("paidAmount")) {
            notification.warning({
                message: "Amount Cannot Be Greater Than Receiving Amount",
            });
            return;
        }

        if (totalAmount == getFieldValue("paidAmount")) {
            notification.warning({
                message: "Enough Amount Added, Cannot Add More Payment Method",
            });
            return;
        }
        const lastData = formData[formData.length - 1];
        if (!lastData.bankId) {
            notification.warning({message: "Please Choose Bank"});
            return;
        }
        if (!lastData.paymentType) {
            notification.warning({message: "Please Choose Payment Type"});
            return;
        }
        if (!lastData.amount) {
            notification.warning({message: "Please Enter Amount"});
            return;
        }

        if (isNaN(lastData.amount)) {
            notification.warning({
                message: "Please Enter Recieved Amount in Numbers",
            });
            return;
        }
        let remainingAmt
        if (totalAmount > 0) {
            remainingAmt = getFieldValue("paidAmount") - totalAmount
        }
        setFormData([
            ...formData,
            {bankId: "", paymentType: "", amount: remainingAmt ? remainingAmt : '', transactionDetails: ""},
        ]);

        setShowDeleteButtons([...showDeleteButtons, true]);
    };

    const companyStateCode = getItemLocalstorage("user")["stateCode"];
    const userSaleCategory = getItemLocalstorage("user")["saleCategory"];
    const {getFieldValue} = props.form;
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let [customerId, setCustomerId] = useState("");
    let [visitNumber, setVisitNumber] = useState();
    let [visitData, setVisitData] = useState({});
    let [clientId, setClientId] = useState("");
    const paidToUserId = getFieldValue("paidToUserId");
    const [banks, setBanks] = useState([]);
    const [saleCategory, setSaleCategory] = useState([]);
    const [anchalSale, setAnchalSale] = useState(false);
    const [ProductReport, setProductReport] = useState([]);
    const updateProductReport = (newProductReport) => {
        setProductReport(newProductReport);
    };
    const [customerState, setCustomerState] = useState("");
    const [customerDiscount, setDiscountState] = useState("");
    const [customerBalance, setCustomerBalance] = useState("");
    const [requestId, setRequestId] = useState(null);
    const [visitId, setVisitId] = useState("");
    const [customerName, setCustomerName] = useState("");
    //credit note useStates
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [creditNoteData, setCreditNoteData] = useState([]);
    const [CreditNoteBalance, setCreditNoteBalance] = useState();
    const [creditNote_id, setCreditNote_id] = useState();
    const [showcreditNoteButton, setCreditNoteButton] = useState(false);
    const [includeBalance, setIncludeBalance] = useState(true);
    const [clientData, setClientData] = useState([]);

    const getCreditNoteOfCustomer = async (customerId) => {
        const data = await fetchCreditNoteByCustomerId(customerId);
        if (data) {
            setCreditNoteData(data.data);
            props.form.setFieldsValue({
                creditNoteBalance: 0,
                remainingAmount: getFieldValue("totalAmount"),
            });
        }
        if (data.data.length != 0) {
            setCreditNoteButton(true);
        } else {
            setCreditNoteButton(false);
        }
    };

    useEffect(() => {
        if (customerId) {
            getCreditNoteOfCustomer(customerId);
        }
    }, [customerId]);

    useEffect(() => {
        const apiRequest123 = (params = {}) => {
            return new Promise(async (resolve) => {
                params._id = clientId;
                const data = await fetchCustomer({...params});
                setCustomerName(data.data[0].name);
                setCustomerState(data.data[0].state);
                setDiscountState(data.data[0].discount);
            });
        };
        if (clientId) {
            apiRequest123();
            setCustomerId(clientId);
        }
    }, [clientId]);
    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        if (x.get("id")) {
            setIsModal2Open(true);
        }
        setRequestId(x.get("id"));
        setClientId(x.get("clientId"));
        setVisitNumber(x.get("visitNumber"));
    }, []);

    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        if (x.get("visitID")) {
            setRequestedMedModal(true);
        }
        setVisitId(x.get("visitID"));
    }, []);

    let totalAmount = 0;
    let netAmount = 0;
    let quantity = 0;
    let balance = 0;

    const calNetAmount = () => {
        if (ProductReport.length == 0) {
            props.form.setFieldsValue({
                totalAmount: 0,
                balance: 0,
                quantity: 0,
                creditNoteBalance: 0,
                remainingAmount: 0,
            });
        }
        ProductReport.forEach((y) => {
            let paidAmount = getFieldValue("paidAmount");
            let cutOffAmount = getFieldValue("cutOffAmount");
            let customDiscount = getFieldValue("customDiscount");
            paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
            cutOffAmount = cutOffAmount ? fixed2Digit(cutOffAmount) : 0;
            customDiscount = customDiscount ? fixed2Digit(customDiscount) : 0;
            totalAmount += Number(y.netAmount);
            netAmount = Number(totalAmount + cutOffAmount - customDiscount);
            quantity += Number(y.quantity);
            balance =
                paidAmount || cutOffAmount || customDiscount
                    ? totalAmount - paidAmount + cutOffAmount - customDiscount
                    : totalAmount;

            if (CreditNoteBalance) {
                balance = CreditNoteBalance ? balance - CreditNoteBalance : balance;
            }

            if (balance < 0) {
                balance = "Not valid Paid Amount";
                props.form.setFieldsValue({
                    totalAmount: totalAmount ? fixed2Digit(totalAmount) : 0,
                    finalAmount: netAmount ? netAmount.toFixed(2) : 0,
                    quantity: quantity,
                    balance: 0,
                });
            } else {
                props.form.setFieldsValue({
                    totalAmount: totalAmount.toFixed(2),
                    finalAmount: netAmount ? netAmount.toFixed(2) : 0,
                    quantity: quantity,
                    balance: balance ? balance.toFixed(2) : 0,
                });
            }
        });
    };

    useEffect(() => {
        if (CreditNoteBalance) {
            const finalBalance = getFieldValue("balance") - CreditNoteBalance;
            const remainingAmount = getFieldValue("totalAmount") - CreditNoteBalance;
            const finalAmount = getFieldValue("finalAmount") - CreditNoteBalance;
            props.form.setFieldsValue({
                balance: finalBalance,
                remainingAmount: remainingAmount,
                finalAmount: finalAmount,
            });
        }
    }, [CreditNoteBalance]);

    const fetchBanks = async () => {
        const {data} = await fetchBank({
            type: "bank",
            results: 100,
            count: 100,
        });
        setBanks(data);
    };

    const fetchSaleFrom = async () => {
        const {data} = await fetchSaleCategory({});
        setSaleCategory(data);
    };

    const fetchVisitByVisitNumber = async (visitNumber) => {
        const data = await fetchVisitNumber(visitNumber);
        if (data) {
            setVisitData(data.products);
        }
    };

    useEffect(() => {
        if (visitNumber) {
            fetchVisitByVisitNumber();
        }
    }, [visitNumber]);

    const apiRequest2 = async () => {
        return new Promise(async () => {
            try {
                let params = {
                    visitId: visitId,
                };
                const data = await fetchVisitAllDetailsFxn({...params});
                setVisitData(data.prescriptionDetails?.products);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    useEffect(() => {
        if (visitId) {
            apiRequest2();
        }
    }, [visitId]);

    useEffect(() => {
        props.form.setFieldsValue({
            paidAmount: 0,
            cutOffAmount: 0,
            customDiscount: 0,
            paymentType: "None",
            salesDate: moment(),
            creditNoteBalance: 0,
            remainingAmount: 0,
        });

        fetchBanks();
        fetchSaleFrom();
    }, []);


    useEffect(() => {
        calNetAmount();
        if (getFieldValue('paidAmount') == 0 || getFieldValue('paidAmount') == undefined || getFieldValue('paidAmount') == null) {
            setFormData(initialState)
        }
    }, [
        ProductReport,
        getFieldValue("paidAmount"),
        getFieldValue("cutOffAmount"),
        getFieldValue("customDiscount"),
    ]);

    const getClient = async (params = {}) => {
        params.results = 50;
        params.count = 50;
        // params.page = 1
        params.customerGroupName = ['EXPENSES (INDIRECT)', 'EXPENSE (DIRECT)']
        const {data} = await fetchBank({...params, regExFilters: ["name",]});
        if (data && data.length) {
            setClientData(data);
        } else {
            setClientData([]);
        }
    };
    useEffect(() => {
        getClient()
    }, []);

    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let findStateCode = _.find(States, (item) => {
                    return item.name === valData.state;
                });
                if (findStateCode) {
                    valData.stateCode = findStateCode.stateCode;
                }
                valData.productReports = ProductReport;
                valData.customerId = customerId;
                valData.paidToUserId = paidToUserId;
                valData.requestId = requestId;
                if (formData[0].bankId != "") {
                    valData.paidToUser = formData;
                }
                if (creditNote_id && CreditNoteBalance) {
                    valData.creditNoteId = creditNote_id;
                    valData.creditNoteBalance = CreditNoteBalance;
                }

                const sumFormDataAmount = formData.reduce(
                    (sum, item) => sum + parseFloat(item.amount) || 0,
                    0
                );

                if (ProductReport.length == 0) {
                    notification.error({
                        message: "Please Add Products",
                    });
                    return;
                }


                const finalAmount = valData.finalAmount;

                if (valData.paidAmount && isNaN(valData.paidAmount)) {
                    notification.warning({
                        message: "Please Enter Received Amount in Numbers",
                    });
                    return;
                }

                const paidAmount = valData.paidAmount;
                if (paidAmount < 0) {
                    notification.error({
                        message: "Paid Amount cannot be negative",
                    });
                    return;
                }
                // if (parseFloat(paidAmount) > parseFloat(finalAmount)) {
                //     notification.error({
                //         message: "Receiving amount cannot be Greater than Final amount",
                //     });
                //     return null;
                // }

                if (!valData.totalAmount) {
                    notification.warning({message: "Please Add Some Items"});
                    return;
                }

                if (!valData.saleCategory) {
                    notification.warning({message: "Please Add Sale Category"});
                    return;
                }
                if (!valData.stateOfSupply) {
                    notification.warning({message: "Please Add State of Supply"});
                    return;
                }
                if (!valData.customerId) {
                    notification.warning({message: "Please Choose Client"});
                    return;
                }

                if (parseFloat(valData.paidAmount) < sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be Greater than total amount",
                    });
                    return;
                } else if (parseFloat(valData.paidAmount) > sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be less than total amount",
                    });
                    return;
                }
                if (valData.saleCategory) {
                    const includeBalance = saleCategory.find((item) => {
                        return item._id == valData.saleCategory;
                    });
                    valData.saleCategoryName = includeBalance.name;
                }

                valData.includeBalance = includeBalance;

                if (valData.balance == 0) {
                    valData.paymentStatus = "Done";
                } else {
                    valData.paymentStatus = "Pending";
                }
                if (valData.trackingDetails) {
                    valData.orderStatus = "Shipped"
                }

                if (valData.customDiscount && !valData.client) {
                    notification.warning({message: "Please Choose Client for Cash Discount"});
                    return;
                }
                if (valData.orderId) {
                    valData.orderId = valData.orderId.toUpperCase();
                }

                if (valData.trackingDetails) {
                    valData.trackingDetails = valData.trackingDetails.toUpperCase()
                }

                if (valData.shipThrough == 'By Hand') {
                    valData.orderStatus = "Delivered"
                }

                let x = await dispatch(addSaleFxn(valData));

                if (x && x.success) {
                    notification.success({message: x.message});
                    setProductReport([]);
                    props.form.setFieldsValue({});
                    setTimeout(() => {
                        navigate("/sale");
                    }, 1000);
                } else {
                    notification.error({message: x.message});
                }
                props.form.setFieldsValue({
                    stateOfSupply: "",
                    invoiceNumber: "",
                    totalAmount: "",
                    paidAmount: "",
                    balance: "",
                    paidToUserId: "",
                    paymentType: "",
                    paymentDetails: "",
                });
                setCustomerId("");
            }
        });
    };

    let inputTypes = {
        upperFields: [
            {
                key: "salesDate",
                label: "Date of Sale",
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        salesDate: x,
                    });
                },
            },
            {
                key: "saleCategory",
                label: "Sale Category*",
                span: "col-md-3",
                placeholder: "Choose One",
                type: "select",
                options: saleCategory,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        saleCategory: x,
                    });
                },
            },
            {
                key: "stateOfSupply",
                label: "State of Supply",
                span: "col-md-3",
                placeholder: "State of supply",
                type: "select",
                options: IndianStates,
                showSearch: true,
                keyAccessor: (x) => x.stateCode,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateOfSupply: x,
                    });
                },
            },
            {
                key: "otherState",
                label: "Other State",
                span: "col-md-3",
                placeholder: "Other State",
                type: "text",
                hidden: getFieldValue('stateOfSupply') !== -1
            },
            {
                key: "orderId",
                label: "Order ID",
                span: "col-md-3",
                placeholder: "Type here..",
                required: !includeBalance,
            },
            {
                key: "trackingDetails",
                label: "Tracking Details",
                span: "col-md-3",
                placeholder: "Tracking Details",

            },
            {
                key: "shipThrough",
                label: "Ship Through",
                span: "col-md-3",
                placeholder: "Ship Through",
                type: "select",
                options: shipthrough,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        shipThrough: x,
                    });
                },
            },

        ],

        lowerFields: [
            {
                key: "totalAmount",
                label: "Total Amount *",
                span: "col-md-2",
                placeholder: "Total Amount",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        totalAmount: totalAmount,
                    });
                },
                disabled: true,
            },
            // {
            //     key: "creditNoteBalance",
            //     label: "Credit Note  *",
            //     span: "col-md-2",
            //     placeholder: "Credit Note Balance",
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             creditNoteBalance: CreditNoteBalance,
            //         });
            //     },
            //     hidden: creditNoteData.length == 0,
            //     disabled: true,
            // },
            // {
            //     key: "remainingAmount",
            //     type: "number",
            //     label: "Remaining Amt",
            //     span: "col-md-2",
            //     placeholder: "Remaining Amount",
            //     hidden: creditNoteData.length == 0,
            //     disabled: true,
            // },
            {
                key: "cutOffAmount",
                label: "Round Off Amount",
                span: "col-md-2",
                placeholder: "Cutoff Amount",
            },
            {
                key: "customDiscount",
                label: "Cash Discount",
                span: "col-md-2",
                placeholder: "Custom Discount",
            },
            {
                key: "client",
                label: "Expense*",
                span: "col-md-2",
                placeholder: "Select From Expense Head",
                type: "select",
                options: clientData,
                onSearch: (value) => {
                    getClient({name: value})
                },
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        client: x,
                    });
                },
                hidden: !getFieldValue("customDiscount")
            },
            {
                key: "finalAmount",
                label: "Final Amount",
                type: "number",
                span: "col-md-2",
                placeholder: "Final Amount",
                disabled: true,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        finalAmount: netAmount,
                    });
                },
            },
            {
                key: "paidAmount",
                label: "Received Amount",
                span: "col-md-2",
                placeholder: "Received Amount",
                hidden: !includeBalance,
            },
            {
                key: "balance",
                label: "Balance *",
                span: "col-md-2",
                placeholder: "Balance",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        balance: balance,
                    });
                },
                disabled: true,
            },
        ],
    };

    const groupType = "SUNDRY DEBTORS";

    useEffect(() => {
        if (getFieldValue("saleCategory") && saleCategory.length != 0) {
            const includeBalance = saleCategory.find((item) => {
                return item._id == getFieldValue("saleCategory");
            });
            if (includeBalance.name == 'Aanchal Sale') {
                setAnchalSale(true)
            } else {
                setAnchalSale(false)
            }
            setIncludeBalance(includeBalance.includeBalance);
            if (includeBalance.name && includeBalance.name == 'Reception') {
                props.form.setFieldsValue({
                    shipThrough: 'By Hand'
                })
            } else {
                props.form.setFieldsValue({
                    shipThrough: ''
                })
            }
        }
    }, [getFieldValue("saleCategory")]);


    useEffect(() => {
        if (getFieldValue("cutOffAmount") > 1) {
            notification.warning({
                message: "Round Off Amount Cannot Be Greater Than Rs. 1",
            });
            props.form.setFieldsValue({
                cutOffAmount: 0
            })
            return;
        }
    }, [getFieldValue("cutOffAmount")])


    // useEffect(() => {
    //     if (userSaleCategory) {
    //         props.form.setFieldsValue({
    //             saleCategory: userSaleCategory
    //         })
    //     }
    // }, [userSaleCategory]);

    useEffect(() => {
        if (userSaleCategory && saleCategory.length != 0) {
            const includeBalance = saleCategory.find((item) => {
                return item._id == getFieldValue("saleCategory");
            });
            if (includeBalance && includeBalance.name == 'Aanchal Sale') {
                setAnchalSale(true)
            } else {
                setAnchalSale(false)
            }
            if (includeBalance && includeBalance.name && includeBalance.name == 'Reception') {
                props.form.setFieldsValue({
                    shipThrough: 'By Hand'
                })
            }
        }
    }, [userSaleCategory && saleCategory.length != 0]);

    useEffect(() => {
        if (customerId === '') {
            setCustomerBalance('')
            setCustomerState('')
        }
    }, [customerId]);

    return (
        <PageHeader title={"Add Sale"}>
            <div className="card">
                <div className="card-body">
                    <div className={"row"}>
                        {clientId ? (
                            <>
                                <div className={"row"}>
                                    <div className={"col-md-3 mb-3"}>
                                        <InputBox title={"Client"}>
                                            <input
                                                style={{height: "45px", color: "black"}}
                                                disabled
                                                className={"form-control"}
                                                type="text"
                                                value={customerName}
                                            />
                                        </InputBox>
                                    </div>
                                </div>
                                <div className="col-1 sale-view ms-2">
                                    {customerId && (
                                        <>
                                            <button
                                                className="btn btn-info btn-sm"
                                                style={{height: "35px"}}
                                                onClick={() => {
                                                    setSaleIsModalOpen(true);
                                                }}
                                            >
                                                Prev.Rec.
                                            </button>
                                            {isSaleModalOpen ? (
                                                <SaleDetailModal
                                                    className={"mt-4"}
                                                    visible={isSaleModalOpen}
                                                    onClose={() => {
                                                        setSaleIsModalOpen(false);
                                                    }}
                                                    customerId={customerId}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={"col-md-3 mb-3"}>
                                    <ChooseCustomer
                                        type={["customer", "patient"]}
                                        groupType={groupType}
                                        customerId={customerId}
                                        customerName={customerName}
                                        callback={(clientData) => {
                                            if (clientData && clientData._id) {
                                                setCustomerId(clientData._id);
                                                setCustomerState(clientData.state);
                                                setDiscountState(clientData.discount);
                                                setCustomerBalance(clientData.balance);
                                            }
                                        }}
                                    />
                                    {
                                        customerId && customerBalance !== undefined ? (
                                            <span><b>Balance: </b> {parseFloat(customerBalance).toFixed(2)}</span>
                                        ) : customerId && customerBalance === 0 ? (
                                            <span><b>Balance: </b> 0.00</span>
                                        ) : null
                                    }

                                    {
                                        customerId && customerState ? (
                                            <span>, <b>State: </b>{customerState}</span>
                                        ) : null
                                    }
                                </div>
                                <div className="col-1 sale-view ms-2">
                                    {customerId && (
                                        <>
                                            <button
                                                className="btn btn-info btn-sm"
                                                style={{height: "35px"}}
                                                onClick={() => {
                                                    setSaleIsModalOpen(true);
                                                }}
                                            >
                                                Prev.Rec.
                                            </button>
                                            {isSaleModalOpen ? (
                                                <SaleDetailModal
                                                    className={"mt-4"}
                                                    visible={isSaleModalOpen}
                                                    onClose={() => {
                                                        setSaleIsModalOpen(false);
                                                    }}
                                                    customerId={customerId}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </>

                        )}


                        {visitId ? (
                            <div className={"col-md-3 mb-3"}>
                                <InputBox title={"Visit Number"}>
                                    <div className="input-group">
                                        <input
                                            className={"form-control"}
                                            type="number"
                                            value={visitNumber}
                                            placeholder="Visit number"
                                            onChange={(e) => setVisitNumber(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-info btn-xs m-2"
                                                onClick={() => fetchVisitByVisitNumber(visitNumber)}
                                            >
                                                search
                                            </button>
                                        </div>
                                    </div>
                                </InputBox>
                            </div>
                        ) : null}
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.upperFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            <div className={"row"} key={getFieldValue("stateOfSupply")}>
                                <div className={"col-md-12 mt-4"}>
                                    <ItemListComponent
                                        isModal2Open={isModal2Open}
                                        setIsModal2Open={setIsModal2Open}
                                        requestedMedModal={requestedMedModal}
                                        setRequestedMedModal={setRequestedMedModal}
                                        visitData={visitData}
                                        requestId={requestId}
                                        visitId={visitId}
                                        ProductReport={ProductReport}
                                        customerId={customerId}
                                        updateProductReport={updateProductReport}
                                        stateOfSupply={getFieldValue("stateOfSupply")}
                                        companyState={companyStateCode}
                                        customerDiscount={customerDiscount}
                                        anchalSale={anchalSale}
                                    />
                                </div>
                            </div>
                            {/*{showcreditNoteButton && ProductReport?.length !== 0 ? (
                                <Button
                                    className="btn btn-gradient-info me-2 mb-4"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Available Credit Note
                                </Button>
                            ) : null}*/}

                            <div className={"row"}>
                                {inputTypes.lowerFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>

                            {isModalOpen && (
                                <Modal
                                    visible={isModalOpen}
                                    title={"Choose CreditNote"}
                                    onClose={() => setIsModalOpen(false)}
                                >
                                    <div>
                                        <InputBox title={"Credit Note"}>
                                            <select
                                                style={{
                                                    height: "45px",
                                                    width: "500px",
                                                }}
                                                className="form-control"
                                                value={CreditNoteBalance}
                                                required
                                                onChange={(e) => {
                                                    const selectedCreditNoteId = e.target.value;
                                                    const selectedCreditNote = creditNoteData.find(
                                                        (creditNote) =>
                                                            creditNote._id === selectedCreditNoteId
                                                    );
                                                    props.form.setFieldsValue({
                                                        finalAmount: getFieldValue("totalAmount"),
                                                    });
                                                    setCreditNoteBalance(selectedCreditNote.saleBalance);
                                                    setCreditNote_id(selectedCreditNoteId);
                                                    setIsModalOpen(false);
                                                    props.form.setFieldsValue({
                                                        creditNoteBalance: selectedCreditNote.saleBalance,
                                                    });
                                                }}
                                            >
                                                <option value="">Select Credit Note</option>

                                                {creditNoteData &&
                                                creditNoteData.some(
                                                    (creditNote) => creditNote.saleBalance !== 0
                                                ) ? (
                                                    <>
                                                        {creditNoteData
                                                            .filter(
                                                                (creditNote) => creditNote.saleBalance !== 0
                                                            )
                                                            .map((creditNote) => (
                                                                <option
                                                                    value={creditNote._id}
                                                                    key={creditNote._id}
                                                                >
                                                                    {`${creditNote.saleBalance}(${creditNote.invoiceNumber})`}
                                                                </option>
                                                            ))}
                                                    </>
                                                ) : (
                                                    <option value="" disabled>
                                                        No record found
                                                    </option>
                                                )}
                                            </select>
                                        </InputBox>
                                    </div>
                                </Modal>
                            )}

                            {getFieldValue("paidAmount") > 0 ? (
                                <Card title={"Payments"} size={"small"}>
                                    <div className="d-flex align-items-end">
                                        <div>
                                            {formData?.map((data, index) => (
                                                <div key={index} className={"item-flex mt-2"}>
                                                    <InputBox title={"Banks"}>
                                                        <select
                                                            style={{height: "45px"}}
                                                            className="form-control"
                                                            value={data.bank}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "bankId",
                                                                    e.target.value
                                                                )
                                                            }
                                                            required="true"
                                                        >
                                                            <option value="">Select Bank</option>
                                                            {banks?.map((bank) => (
                                                                <option key={bank._id} value={bank._id}>
                                                                    {bank.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Payment Type"}>
                                                        <select
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className="form-control"
                                                            value={data.paymentType}
                                                            required
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "paymentType",
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option value="">Select Payment Type</option>
                                                            {PaymentTypes?.map((PaymentTypes) => (
                                                                <option>{PaymentTypes.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Amount"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            type="text"
                                                            value={data.amount}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "amount",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Amount"
                                                        />
                                                    </InputBox>

                                                    <InputBox title={"Transaction Details"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            value={data.transactionDetails}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "transactionDetails",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Transaction Details"
                                                        />
                                                    </InputBox>
                                                    <InputBox className="deleteBtn">
                                                        <div key={index} className={"item-flex mt-3"}>
                                                            {index > 0 &&
                                                            showDeleteButtons[index] && ( // Condition added to not show delete button for zero index
                                                                <a
                                                                    className="empty_btn"
                                                                    onClick={() => handleDelete(index)}
                                                                >
                                                                    <i className="fa fa-trash-alt"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </InputBox>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <InputBox className={"pb-2"}>
                                                <a
                                                    className="addBtn  btn-link"
                                                    onClick={handleAddField}
                                                >
                                                    Add More
                                                </a>
                                            </InputBox>
                                        </div>
                                    </div>
                                </Card>
                            ) : null}
                        </div>

                        <button type="submit" className="btn btn-gradient-info me-2">
                            Submit
                        </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/sale"}>
                            Cancel
                        </HyperLink>
                    </Form>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(Sales);
