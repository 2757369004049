import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  DatePicker,
  Select2,
  InputBox,
  Card,
} from "../../components/Elements/appUtils";
import {notification} from "../../components/Elements/appUtils";
import { getProductDataUrl, getproducts } from "../products/api";
import { getToken } from "../../request";
import TableMain from "../../components/Elements/Table";
import _ from "lodash";
import { getProductUrl, getStockUrl } from "./api";
import {arraySorterByParticularField} from "../../components/_utils/appUtils";

let { Option } = Select2;
let initState = {
  productId: "" || undefined,
  productName: "",
  quantity: "",
  price: "",
  inclusivePrice: "",
  pUnit: "",
  hsn: "",
  subTotal: "",
  discount: "",
  discountAmount: "",
  expiryDate: "",
  manufacturingDate: "",
  taxableAmount: "",
  igstAmount: "",
  cgstAmount: "",
  sgstAmount: "",
  igstTax: "",
  gst: "",
  uom: "",
  totalAmount: "",
  gstAmount: "",
  netAmount: "",
  stateOfSupply: "",
  customerId: "",
  reportType: "" || undefined,
  transactionType: "",
};

const ItemListComponent = ({
  ProductReport,
  updateProductReport,
  statesAreEqual,
}) => {
  const [stock, setStock] = useState([]);
  let [productState, setProductState] = useState(initState);
  const [expDate, setexpDate] = useState();
  const [manfDate, setmanfDate] = useState();
  productState.expiryDate = expDate;
  productState.manufacturingDate = manfDate;

  const fetchStock = async () => {
    try {
      const response = await axios.get(getProductUrl(), getToken());
      console.log(response,'response')
      const sortedArray = arraySorterByParticularField(response.data.data, 'name');
      console.log(sortedArray,'sortedArray')
      setStock(sortedArray);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchStock();
  }, []);

  const onSubmit = () => {
    if (!productState.productId) {
      notification.warning({ message: "Please Choose Product" });
      return;
    }
    if (!productState.quantity) {
      notification.warning({ message: "Please Enter Quantity" });
      return;
    }
    const updatedProductReport = [
      ...ProductReport,
      { ...productState, serialNo: ProductReport.length + 1 },
    ];
    updateProductReport(updatedProductReport);

    setProductState(initState);
    setmanfDate();
    setexpDate();
  };

  const handleChange1 = (e, fieldName) => {
    const { value } = e.target;

    if (fieldName === "productId") {
      // Find the selected product from the product state
      const selectedProduct = stock.find((stock) => stock._id === value);
      if (selectedProduct) {
        if (selectedProduct.type == "Inclusive") {
          const totalAmount = selectedProduct.purchasePrice * 1;
          const taxableAmount =
            (totalAmount * 100) / (selectedProduct.gst + 100);
          const gstAmount = (taxableAmount * selectedProduct.gst) / 100;
          const netAmount = taxableAmount + gstAmount;
          let cgstAndSgstAmount = 0;
          let igstAmount = 0;
          if (statesAreEqual) {
            igstAmount = 0;
            cgstAndSgstAmount = gstAmount / 2;
          } else {
            igstAmount = gstAmount;
            cgstAndSgstAmount = 0;
          }
          setProductState({
            ...productState,
            productId: value,
            productName: selectedProduct.name,
            price: selectedProduct.purchasePrice,
            pUnit: selectedProduct.unitCode,
            hsn: selectedProduct.hsnCode,
            gst: selectedProduct.gst,
            uom: selectedProduct.unitCode,
            reportType: selectedProduct.type,
            quantity: 1,
            totalAmount: totalAmount.toFixed(2),
            taxableAmount: taxableAmount.toFixed(2),
            gstAmount: gstAmount.toFixed(2),
            cgstAmount: cgstAndSgstAmount.toFixed(2),
            sgstAmount: cgstAndSgstAmount.toFixed(2),
            igstTax: selectedProduct.gst,
            igstAmount: igstAmount.toFixed(2),
            netAmount: netAmount.toFixed(2),
            discount: 0,
            discountAmount: 0,
          });
        } else {
          const totalAmount = selectedProduct.purchasePrice * 1;
          const gstAmount = (totalAmount * selectedProduct.gst) / 100;
          const netAmount = totalAmount + gstAmount;
          let cgstAndSgstAmount = 0;
          let igstAmount = 0;
          if (statesAreEqual) {
            igstAmount = 0;
            cgstAndSgstAmount = gstAmount / 2;
          } else {
            igstAmount = gstAmount;
            cgstAndSgstAmount = 0;
          }
          setProductState({
            ...productState,
            productId: value,
            productName: selectedProduct.name,
            price: selectedProduct.purchasePrice,
            pUnit: selectedProduct.unitCode,
            hsn: selectedProduct.hsnCode,
            gst: selectedProduct.gst,
            uom: selectedProduct.unitCode,
            reportType: selectedProduct.type,
            quantity: 1,
            totalAmount: totalAmount.toFixed(2),
            taxableAmount: totalAmount.toFixed(2),
            gstAmount: gstAmount.toFixed(2),
            cgstAmount: cgstAndSgstAmount.toFixed(2),
            sgstAmount: cgstAndSgstAmount.toFixed(2),
            igstTax: selectedProduct.gst,
            igstAmount: igstAmount.toFixed(2),
            netAmount: netAmount.toFixed(2),
            discount: 0,
            discountAmount: 0,
          });
        }
      }
      // Update the price field with the selected product's price
    } else if (
      fieldName === "quantity" &&
      productState.reportType == "Exclusive"
    ) {
      const quantity = parseFloat(value);
      const subtotal1 = quantity * productState.price;
      const taxableAmount = subtotal1 - productState.discountAmount;
      const gstAmount = (taxableAmount * productState.gst) / 100;
      let cgstAndSgstAmount = 0;
      let igstAmount = 0;
      if (statesAreEqual) {
        igstAmount = 0;
        cgstAndSgstAmount = gstAmount / 2;
      } else {
        igstAmount = gstAmount;
        cgstAndSgstAmount = 0;
      }
      const netAmount2 = taxableAmount + gstAmount;
      setProductState({
        ...productState,
        [fieldName]: value,
        totalAmount: subtotal1.toFixed(2),
        taxableAmount: taxableAmount.toFixed(2),
        gstAmount: gstAmount.toFixed(2),
        cgstAmount: cgstAndSgstAmount.toFixed(2),
        sgstAmount: cgstAndSgstAmount.toFixed(2),
        igstTax: productState.gst,
        igstAmount: igstAmount.toFixed(2),
        netAmount: netAmount2.toFixed(2),
      });
    } else if (
      fieldName === "quantity" &&
      productState.reportType == "Inclusive"
    ) {
      const quantity = parseFloat(value);
      const subtotal1 = quantity * productState.price;
      const discountAmount = (subtotal1 * productState.discount) / 100;
      const discountedAmount = subtotal1 - discountAmount;
      const taxableAmount = (discountedAmount * 100) / (100 + productState.gst);
      const gstAmount = (taxableAmount * productState.gst) / 100;
      const netAmount2 = taxableAmount + gstAmount;
      let cgstAndSgstAmount = 0;
      let igstAmount = 0;
      if (statesAreEqual) {
        igstAmount = 0;
        cgstAndSgstAmount = gstAmount / 2;
      } else {
        igstAmount = gstAmount;
        cgstAndSgstAmount = 0;
      }

      setProductState({
        ...productState,
        discountAmount: discountAmount.toFixed(2),
        [fieldName]: value,
        totalAmount: subtotal1.toFixed(2),
        taxableAmount: taxableAmount.toFixed(2),
        gstAmount: gstAmount.toFixed(2),
        cgstAmount: cgstAndSgstAmount.toFixed(2),
        sgstAmount: cgstAndSgstAmount.toFixed(2),
        igstTax: productState.gst,
        igstAmount: igstAmount.toFixed(2),
        netAmount: netAmount2.toFixed(2),
      });
    } else {
      setProductState({
        ...productState,
        [fieldName]: value,
      });
    }
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      key: "hsn",
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "UoM",
      dataIndex: "uom",
      key: "uom",
    },
    {
      title: "Action",
      key: "delete",
      render: (text, record, index) => (
        <a className="nav-link" onClick={() => handleDelete(index)}>
          <i className="mdi mdi-delete"></i>
        </a>
      ),
    },
  ];

  const handleDelete = (index) => {
    const updatedProductReport = [...ProductReport];
    updatedProductReport.splice(index, 1);
    updateProductReport(updatedProductReport);
  };

  const getColumns = () => {
    let columnArr = [];
    _.each(columns, (item) => {
      if (!item.show) {
        columnArr.push(item);
      }
    });
    return columnArr;
  };

  return (
    <>
      <Card title={"Select Items"} size={"small"}>
        <div className={"item-flex"}>
          <InputBox title={"Product"} className={"flex2"}>
            <select
              style={{ height: "45px" }}
              className="form-control"
              value={productState.productId}
              onChange={(e) => handleChange1(e, "productId")}
              required
            >
              <option value="">Select Product</option>
              {stock.map((stock) => (
                <option key={stock._id} value={stock._id}>
                  {stock.name}
                </option>
              ))}
            </select>
          </InputBox>
          <InputBox title={"Qty."}>
            <input
              className={"form-control"}
              type="number"
              value={productState.quantity}
              placeholder="Quantity"
              onChange={(e) => handleChange1(e, "quantity")}
            />
          </InputBox>
          <InputBox className={"flex_none"}>
            <a
              className="addBtn btn-success fs-5"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              +
            </a>
          </InputBox>
        </div>
      </Card>
      <div key={ProductReport.length} className="mt-4 mb-4">
        <h2>Items</h2>
        <TableMain
          dataSource={ProductReport}
          columns={getColumns()}
          // ref={tableRef}
        />
      </div>
    </>
  );
};
export default ItemListComponent;